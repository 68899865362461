<div id="appointments">
  <p class="home-section-subtitle">
    <span class="title-label">
      {{'consult_again' | translate}}
    </span>
    <span class="see-all" (click)="onSeeAll()">
      {{'See_all' | translate}}
      <img class="title-icon" src="assets/icons/slot/right_arrow.svg" alt="arrow">
    </span>
  </p>
  <div class="appt-list-view" *ngIf="!showAsSlide">
    <div class="appt-list-card" *ngFor="let item of consultAgainLmt | sort : 'consultDttm' : 'moment'">
      <ng-container [ngTemplateOutlet]="APPTCARD" [ngTemplateOutletContext]="{item:item}"></ng-container>
    </div>
  </div>
  <swiper-container pagination="true" class="appt-list-view" *ngIf="showAsSlide" #apptSwiper>
    <swiper-slide class="appt-list-card-slider" *ngFor="let item of consultAgainLmt | sort : 'consultDttm' : 'moment'">
      <ng-container [ngTemplateOutlet]="APPTCARD" [ngTemplateOutletContext]="{item:item}"></ng-container>
    </swiper-slide>
  </swiper-container>
</div>

<ng-template #APPTCARD let-item="item">
  <div class="appt-list-card-for-slider" (click)="bookAgain(item)">
    <div class="appt-card-header video-header" *ngIf="item['appointmenttype'] == 'VIDEO'">
      <img class="type-image" src="assets/icons/Appointments/video.svg" alt=""> {{'Video_consultation' | translate}}
    </div>
    <div class="appt-card-header clinic-header" *ngIf="item['appointmenttype'] != 'VIDEO'">
      <img class="type-image" src="assets/icons/Appointments/clinic.svg" alt=""> {{'Clinic_consultation' | translate}}
    </div>
    <div class="appointment-content">
      <div class="person-avatar-wrapper">
        <app-person-avatar [url]="item.imageUrl"> </app-person-avatar>
        <!-- <app-practitioner-avatar
          [url]="(item['resourceId']|partitionerDetails: item['facility_id']:'resourceAvatarURL')">
        </app-practitioner-avatar> -->
      </div>
      <div class="appt-context">
        <div class="appt-title">{{(item['resourceId']|partitionerDetails:item['facility_id']:'resourceName'| translate : 'LOCALE' : 'name') || 'Doctor'}}</div>
        <div class="appt-subtitle">{{(item['resourceId']|partitionerDetails: item['facility_id']:'specialityId') | specialityDetails: item['facility_id']:'specialityName'| translate : 'LOCALE' : 'name'}}</div>
      </div>
    </div>
    <div class="appointment-for-content">
      <p class="appt-for"> {{'For:' | translate}}
        <span class="bold" *ngIf="item && item['patientDetails']">{{item['patientDetails']['personname']}}</span>
    </div>
    <div class="appt-date-context">
      <div class="appt-date-section">{{'Last consulted:'}}
        <span class="bold">
          <app-appt-time type="CARD2" [time]="item['consultDttm']" [sourceFormat]="'YYYY-MM-DD HH:mm'" [destinationFormat]="'D MMM YY, hh:mm a'" [showLocalTime]="('' | apptTime : 'CHECK': null:null: null: item['facility_id'])" [facilityId]="item['facility_id']"></app-appt-time>
        </span>
      </div>
    </div>
  </div>
</ng-template>


<app-recently-consulted *ngIf="showConsultAgainList" (close)="closeModal()" (complete)="complete($event)"></app-recently-consulted>

<app-book-appointment *ngIf="bookAppt" (close)="closeApptView($event)" [chooseSlot]="prefillData" [skipPatientSelect]="true" [bookingAttr]="bookingAttributes"></app-book-appointment>
