import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, ViewChild } from '@angular/core';
import { UserService } from '../services/user.service';
import * as moment from 'moment';
import { environment } from '@env/environment';
import { AppointmentService } from '../services/appointment.service';
import { TranslatePipe } from '../pipes/translate.pipe';
import { PaymentService } from '../services/payment.service';
import { visitType, apptFlowType, discountType, PromoDetails, POINTSTYPE } from '@type';
import { CouponService } from '../services/coupon.service';
import { GoogleTagsService } from '../services/google-tags.service';
import * as _ from 'lodash';
import { Subject, forkJoin } from "rxjs";
import { POINTSTYPEDEFAULT, PROMO_PARAMS } from '../app.const';
import { AltpaymentInputComponent } from '../components/altpayment-input/altpayment-input.component';

@Component({
  selector: 'app-appointment-confirm',
  templateUrl: './appointment-confirm.component.html',
  styleUrls: ['./appointment-confirm.component.scss'],
  providers: [TranslatePipe]
})
export class AppointmentConfirmComponent implements OnInit {
  @Input() _input: any;
  @Input() visitType: visitType;
  @Output() complete: EventEmitter<any>;
  @Output() action: EventEmitter<any>;
  @Input() flowType: apptFlowType;
  @Input() couponData: any;
  @Input() promoData: any;
  @Input() promoApplicable: boolean;
  @Input() couponApplicable: boolean;
  @Input() isDiscountType: discountType;
  @Input() referralDetail: any;
  @Input() isReferral: boolean;
  @Input() isPolicyValidated: boolean;
  refreshInternalCoupon: Subject<Object> = new Subject<Object>();
  refreshFlip: Subject<Object> = new Subject<Object>();
  refresh: Subject<Object> = new Subject<Object>();

  public currency_code: string;
  public payType: 'C' | 'X';
  public freeFollowUpYn: string;
  public patientInsurance: any;
  public payLater: boolean;
  public prevPayment: Object = null;
  public couponDiscount: number = 0;
  public promoDiscount: number = 0;
  public payableCharge: number;
  public policyValidation: any = null;
  // public billingGroupCode: any = null;
  public couponInputs: any;

  private facilityId: string;
  private speciality: string;
  private practitioner: string;
  private patient: string;
  private slot: string;
  private paymentGatewayUrl: string;
  private emerNumber: string;
  private hasDiscount: boolean = false;
  private referralCode: string = '';
  private enablePayOnConsultForInsurance: boolean = false;

  public fromSlotBlockPrompt: boolean = false;
  public policyInfo: any;
  public isFlip: boolean = false;
  public showPolicyExpirePrompt: boolean = false;
  public showBlockSlotPrompt: boolean = false;
  public showCashPrompt: boolean = false;
  public showInsPrompt: boolean = false;
  public showCoupon: boolean = false;
  public showInsuranceModal: boolean = false;
  public enableReferralCode: boolean = false;
  public isEnablePromo: boolean = false;
  public isEnableCoupon: boolean = false;
  public isEnablePoints: boolean = false;
  public canContinue: boolean;
  public accept: boolean = true;
  public referralControl: any;
  public applyPromoDiscYn: boolean
  public fetchingCharge: boolean = false;
  public promoInputs: any;
  public actualInsuranceDetails: any;
  public enableCallCenter: boolean = false;
  public promptLabels: any;

  public readonly promoParams: any = PROMO_PARAMS;
  private readonly logData = [];
  private userAction: any = null;

  //New payment flow
  public state: 'FETCH' | 'COMPLETE' | 'ERROR' | 'COMPLETERESCHEDULEERROR';
  public showManageInsurance: boolean = false
  // public canPayOptional: boolean = false
  // public canPayOptionalGlobal: boolean = false
  public chargeDetails: any = {}
  private promptPreset = {
    chargeData: {},
    discountData: {}
  }
  public discountDetails: PromoDetails
  public billingGroup: any;
  public validationDetails: any
  public canPayToPract: boolean = false;
  public canPay: boolean = false;
  public canPayOptional: boolean = false
  public canPayOptionalGlobal: boolean = false
  public prevPaymentCost: any = 0
  @ViewChild('coupon', { static: false }) coupon: AltpaymentInputComponent;
  @ViewChild('points', { static: false }) points: AltpaymentInputComponent;

	public readonly pointsType: POINTSTYPE = POINTSTYPEDEFAULT
  public chargeVatInfoRef:any;
  public chargeVatDetails:any;
  constructor(private user: UserService, private apptService: AppointmentService, private translatePipe: TranslatePipe, private paymentService: PaymentService, private couponService: CouponService, private gtmService: GoogleTagsService, private cdf: ChangeDetectorRef) {
    this.complete = new EventEmitter<any>();
    this.action = new EventEmitter<any>();
    // this.scheduleCost = {};
    this.payType = 'C'
  }

  ngOnInit() {
    this.user.getConfigurationInfo.subscribe(data => {
      this.isEnablePromo = data['pxAppSettings'] && data['pxAppSettings']['enablePromoYn'] == 'Y' ? true : false;
      this.isEnableCoupon = data['pxAppSettings'] && data['pxAppSettings']['enableCouponYn'] == 'Y' ? true : false;
      this.isEnablePoints = data['pxAppSettings'] && data['pxAppSettings']['enablePointsYn'] == 'Y' ? true : false;
      this.enablePayOnConsultForInsurance = data['pxAppSettings'] && data['pxAppSettings']['enablePayOnConsultForInsurance'] == 'Y' ? true : false;
      if (this._input && this._input['in'] && this._input['in']['home'] && this._input['in']['home']['visitType']) {
        this.enableReferralCode = this._input['in']['home']['visitType'] == 'VIDEO' ? data['pxAppSettings'] && data['pxAppSettings']['referral'] && data['pxAppSettings']['referral']['canReferForVC'] : data['pxAppSettings'] && data['pxAppSettings']['referral'] && data['pxAppSettings']['referral']['canReferForAppt'];
        this.referralControl = data['pxAppSettings'] && data['pxAppSettings']['referral']['controls'];
      }
      this.paymentGatewayUrl = data.features['paymentGateway']['paymentGatewayUrl']
    })
    console.log('input', this._input)
    this.facilityId = this._input.in['facility'] ? this._input.in['facility']['id'] : this._input.in['speciality'] ? this._input.in['speciality']['facilityId'] : this._input.in['practitioner']['facilityId'];
    this.speciality = this._input.in['speciality'];
    this.practitioner = this._input.in['practitioner'];
    this.patient = this._input.in['patient'];
    this.slot = this._input.in['slot'];
    this.prevPayment = this._input.in['exist'] ? this._input.in['exist']['payment'] : null;
    this.currency_code = this.user.getCurrency() || environment['CURRENCY'];
    this.user.getUpdateInfo('facilities').subscribe(data => {
      let facility = data.facilities.find(facility => {
        return facility.facilityId == this.facilityId
      });
      if (facility) {
        let dir = facility.directory.find(dir => {
          return dir.type == 'EMR'
        });
        if (dir) {
          this.emerNumber = dir.number
        }
      }
    })
    if (this.flowType == 'RESCHEDULE' && this.prevPayment && this.prevPayment['status'] == 'PAID') {
      this.prevPaymentCost = this.user.fixDecimalPlaces(this.prevPayment['amount'])
    }
    this.getAppointmentCharges();
  }

  private openSlotBlockPrompt(err) {
    this.showBlockSlotPrompt = true;
  }

  public initRescheduleAppointment() {
    console.log(this._input.in['patient']['personid'], "get reschedule", this.patient);
    this.patient = this._input.in['patient'];
    let data = {
      patientPersonId: this._input.in['patient']['personid'],
      uhid: this.patient['id'],
      facilityId: this.facilityId,
      clinicCode: this.slot['clinicCode'],
      resourceId: this.practitioner['id'],
      date: this.user.getMoment(this.slot['date']).format("YYYY-MM-DD"),
      time: this.slot['slot'],
      personName: this.patient['name'],
      dob: this.user.getMoment(this.patient['dob']).format("YYYY-MM-DD"),
      gender: this.patient['gender'],
      practitionerName: this.practitioner['name'][0]['name'],
      specialityName: this.speciality['name'][0]['name'],
      patientName: this.patient['name'],
      visitType: this.visitType,
      appointmentNumber: this._input.in['exist']['apptRefNo'],
    }
    if (this.freeFollowUpYn == 'Y') {
      data['freeFollowUpYn'] = this.freeFollowUpYn
    }
    if (this.payType == 'X') {
      data['isInsurance'] = true;
    }
    this.apptService.rescheduleAppointment(data).subscribe(response => {
      this.gtmService.pushGTMEvent(null, 'appointment_reschedule', { booking_no: this._input.in['exist']['apptRefNo'] });
      let d = {
        name: 'book_success',
        status: 'S',
        currency: this.currency_code,
        category: this.billingGroup ? this.billingGroup : 'CASH',
        medium: 'FACILITY'
      }
      this.completeSelection(d);
    })
  }

  //NEW FLOW
  private setCanPay() {
    if (this.visitType == 'VIDEO') {
      this.canPayToPract = this.canPay = this.apptService.checkPractitionerControls(this.facilityId, this.practitioner['id'], 'PAYONBOOKVC', this.practitioner);
      this.canPayOptional = this.apptService.checkPractitionerControls(this.facilityId, this.practitioner['id'], 'PAYLATERVC', this.practitioner);
    } else {
      this.canPayToPract = this.canPay = this.apptService.checkPractitionerControls(this.facilityId, this.practitioner['id'], 'PAYONBOOKVISIT', this.practitioner);
      this.canPayOptional = this.apptService.checkPractitionerControls(this.facilityId, this.practitioner['id'], 'PAYLATERVISIT', this.practitioner);
    }
    this.canPayOptionalGlobal = this.canPayOptional
  }

  private getAppointmentCharges(forcePaymentWithCash: boolean = false, actualChargeData = null) {
    this.setCanPay()
    this.setPromptPreset(null, null)
    this.fetchingCharge = true;
    this.actualInsuranceDetails = null
    this.state = "FETCH";
    const chargePayload = [{
      apptDate: this.user.getMoment(this.slot['date']).format("YYYY-MM-DD"),
      apptFromTime: this.slot['slot'],
      apptRefNo: this._input.in['exist'] ? this._input.in['exist']['apptRefNo'] : '',
      clinicCode: this.slot['clinicCode'],
      facilityId: this.facilityId,
      patientId: this.patient['id'],
      practitionerId: this.practitioner['id'],
      visitType: this.visitType,
      forcePaymentWithCash: forcePaymentWithCash,
      forceBookingSlotYN: null,
      specialityId: this.speciality['id']
    }];
    if (this.flowType == 'RESCHEDULE') {
      chargePayload[0]['isReschedule'] = true
      chargePayload[0]['isToastBlockOnCondition'] = {
        enable: true,
        condition: {
          conditionValue: 'POLICYNVFDATE',
          conditionType: 'INCLUDES'
        }
      }
    }
    if (forcePaymentWithCash) {
      this.userAction = {
        changeFromInsToCash: true,
        patientId: this.patient['id'],
        insuranceDetails: actualChargeData.validationDetails,
        billingGroup: this.billingGroup,
        reason: 'USERACTIONWITHCASH'
      }
    }

    const discountPayload = [{
      facilityId: this.facilityId,
      practitionerId: this.practitioner['id'],
      visitType: this.visitType,
      forceBookingSlotYN: null
    }];
    forkJoin(this.apptService.getVisitCharge(chargePayload), this.apptService.getConsultationDiscount(discountPayload)).subscribe(([chargeResponse, discountResponse]) => {
      console.log('charge data----', chargeResponse[0], discountResponse[0])
      let discountData = discountResponse[0]
      let chargeData = chargeResponse[0];
      this.logData.push({
        chargeData: chargeData,
        timestamp: moment().format()
      })
      let chargeDetails: any = {
        consultationFee: Number(chargeData['consultationFee']) || 0,
        hospitalFee: Number(chargeData['hospitalFee']) || 0,
        totalAmount: Number(chargeData['totalAmount']) || 0, // Total consultation fee
        payableAmount: Number(chargeData['patientPayableAmount']) || 0, //Actual Patient payable fee
        discountAmount: 0,
        discountedPayable: Number(chargeData['patientPayableAmount']) || 0,
        netPayable: Number(chargeData['patientPayableAmount']) || 0, //Final Patient payable fee
        promoDiscount: 0,
        couponDiscount: 0,
        isPromoApplied: false,
        isCouponApplied: false,
        isFreeFollowUp: false,
        couponTitle: [],
        canPayOptional: false,
        insuranceDetails: chargeData.insuranceFinDtls ? chargeData.insuranceFinDtls[0] : null
      }
      if (chargeData && chargeData.enableManageInsurance) {
        this.showManageInsurance = true;
      } else {
        this.showManageInsurance = false;
      }
      console.log('enableManageInsurance', this.showManageInsurance)
      if (chargeData && chargeData.checkPolicyValidation) {
        if (chargeData.validationDetails && chargeData.validationDetails.promptCashAppt) {
          this.promptPolicyStatus(chargeData, 'PEXPIRED', discountData)
        } else {
          if (chargeData.validationDetails && chargeData.validationDetails.canPayWithCash)
            this.promptPolicyStatus(chargeData, 'CANPAYCASH', discountData)
          else
            this.proceedApptFlow(chargeData, discountData)
        }
      } else if (discountData) {
        if (discountData.criteriaId == 'DISCOUNT-PERCENTAGE') {
          chargeDetails.discountPercentage = Number(discountData.criteriaValue);
          chargeDetails.discountAmount = (chargeDetails.payableAmount) * (chargeDetails.discountPercentage / 100);
          chargeDetails.netPayable = chargeDetails.payableAmount - chargeDetails.discountAmount;
        } else if (discountData.criteriaId == 'DISCOUNT-AMOUNT') {
          chargeDetails.discountAmount = Number(discountData.criteriaValue);
          chargeDetails.netPayable = chargeDetails.payableAmount - chargeDetails.discountAmount;
        } else {
          this.proceedApptFlow(chargeData, discountData)
        }
        chargeDetails.discountedPayable = chargeDetails.netPayable;
      } else {
        this.proceedApptFlow(chargeData, discountData)
      }
      if (chargeData.discount && chargeData.discount.discountApplicable == 'Y') {
        chargeDetails.discountDetails = {
          applicable: true,
          charge: chargeData['charge']
        }
      } else {
        chargeDetails.discountDetails = {
          applicable: false,
          charge: null
        }
      }
      if (chargeData.freeFollowUpYn == 'Y')
        chargeData.isFreeFollowUp = true;
      this.canPayOptional = this.canPayOptionalGlobal
      // For future reference - refer PX app
      // if (chargeDetails.insuranceDetails) {
      //   if (this.visitType !== 'VIDEO' && !this.permission.canViewOption('CANPAYLATERFORINSURANCEHOSP')) {
      //     this.canPayOptional = false;
      //   }
      //   if (this.visitType === 'VIDEO' && this.permission.canViewOption('RESCTICTPAYLATERFORINSURANCEVIDEO')) {
      //     this.canPayOptional = false;
      //   }
      // }
      this.chargeDetails = chargeDetails;
      this.calculatePayable()

      if (chargeData['disableCoupon']) {
        this.isEnableCoupon = false
      }
      if (chargeData['disablePoints']) {
        this.isEnablePoints = false
      }
      if (Number(this.chargeDetails.netPayable) == 0) {
        this.canPay = false;
      } else {
        this.canPay = true;
      }
      this.state = "COMPLETE";

    }, err => {
      console.log('Error:', err)
      this.state = "ERROR";
      if (err && err.error && err.error.error && err.error.error.includes('POLICYNVFDATE')) {
        console.log('POLICYNVFDATE', err.error);
        let policydata = (err.error.data && err.error.data[0]) ? err.error.data[0] : {};
        this.promptPolicyStatus(err.error, 'POLICYNVFDATE', null, policydata)
      }
    }, () => {
      console.log('Completed')
    })
  }

  public async promptPolicyStatus(chargeData, type, discountData, policydata?) {
    const validationDetails = chargeData['validationDetails'];
    switch (type) {
      case 'PEXPIRED':
        this.getConfirmationForCashPayment(chargeData, validationDetails.promptLabels);
        this.setPromptPreset(chargeData, discountData)
        break;
      case 'CANPAYCASH':
        this.getConfirmationForInsPayment(chargeData, validationDetails.promptLabels);
        this.setPromptPreset(chargeData, discountData)
        break;
      case 'POLICYNVFDATE':
        this.openPolicyExpirePrompt(policydata);
        this.setPromptPreset(chargeData, discountData)
        break;
    }
  }

  private setPromptPreset(chargeData, discountData) {
    this.promptPreset = { chargeData, discountData };
  }
  private getConfirmationForCashPayment(data, promptLabels) {
    this.promptLabels = promptLabels;
    this.showCashPrompt = true
  }
  private getConfirmationForInsPayment(data, promptLabels) {
    this.promptLabels = promptLabels;
    this.showInsPrompt = true
  }

  public proceedApptFlow(chargeData, discountData) {
    console.log('chargeData', chargeData)
    if (chargeData && chargeData.discount && chargeData.discount.discountApplicable == 'Y') {
      this.discountDetails = {
        applicable: true,
        charge: chargeData['charge']
      }
      console.log("this.promoDetails on appt confirm", this.discountDetails);
    } else {
      this.discountDetails = {
        applicable: false,
        charge: null
      }
    }

    if (chargeData.freeFollowUpYn == 'Y') {
      this.freeFollowUpYn = chargeData.freeFollowUpYn;
      this.canPay = false;
    } else {
      this.freeFollowUpYn = null;
    }

    this.billingGroup = chargeData.billingGroupCode
    if (chargeData.insuranceFinDtls) {
      let canPayWithPolicy = chargeData && chargeData.checkPolicyValidation && chargeData.validationDetails && chargeData.validationDetails.canPayWithPolicy
      if (this.visitType == 'VIDEO' && !canPayWithPolicy) {
        this.enableCallCenter = true;
      } else {
        console.log('ins case proceed')
        this.enableCallCenter = false;
        this.canPay = canPayWithPolicy ? this.canPay : (this.canPay && this.enablePayOnConsultForInsurance);
      }
      this.patientInsurance = chargeData.insuranceFinDtls[0];
    }

    if (this.billingGroup == 'CASH' && chargeData.isInsurancePatient) {
      if (this.visitType == 'VIDEO') {
        this.actualInsuranceDetails = chargeData.insuranceDetails.insuranceFinDtls[0] || {};
      }
    }
    if (chargeData && chargeData.checkPolicyValidation && chargeData.validationDetails) {
      this.validationDetails = chargeData.validationDetails;
    } else {
      this.validationDetails = null;
    }

    let chargeDetails: any = {
      consultationFee: Number(chargeData['consultationFee']) || 0,
      hospitalFee: Number(chargeData['hospitalFee']) || 0,
      totalAmount: Number(chargeData['totalAmount']) || 0,
      payableAmount: Number(chargeData['patientPayableAmount']) || 0,
      discountAmount: 0,
      netPayable: Number(chargeData['patientPayableAmount']) || 0,
      discountedPayable: Number(chargeData['patientPayableAmount']) || 0,
      discountDetails: this.discountDetails
    }

    if (discountData) {
      if (discountData.criteriaId == 'DISCOUNT-PERCENTAGE') {
        chargeDetails.discountPercentage = Number(discountData.criteriaValue);
        chargeDetails.discountAmount = (chargeDetails.payableAmount) * (chargeDetails.discountPercentage / 100);
        chargeDetails.netPayable = chargeDetails.payableAmount - chargeDetails.discountAmount;
        chargeDetails.discountedPayable = chargeDetails.payableAmount - chargeDetails.discountAmount;
      } else if (discountData.criteriaId == 'DISCOUNT-AMOUNT') {
        chargeDetails.discountAmount = Number(discountData.criteriaValue);
        chargeDetails.netPayable = chargeDetails.payableAmount - chargeDetails.discountAmount;
        chargeDetails.discountedPayable = chargeDetails.payableAmount - chargeDetails.discountAmount;
      }
    }
    this.chargeVatInfoRef=_.cloneDeep(chargeData.parsedCharge);
      if(chargeData && chargeData.parsedCharge){
        this.calculateVatCharges(chargeData.parsedCharge);
      }
    this.chargeDetails = chargeDetails;
    this.calculatePayable()
    console.log('charge details', chargeDetails, discountData, this.chargeDetails)
    if (Number(this.chargeDetails.netPayable) == 0) {
      this.canPay = false;
    }
    this.state = "COMPLETE";
  }

  public initBookAppointment(type: 'PAY' | 'BOOK' | 'PAYLATER') {
    this.user.clearInclinicSelection();
    if (type == 'PAY') {
      this.initTransaction();
    } else {
      if (type == 'PAYLATER') {
        this.chargeDetails.isCouponApplied = false;
        this.chargeDetails.isPromoApplied = false
      }
      this.bookAppointment()
    }
  }

  private isPromoApplied() {
    return this.chargeDetails && this.chargeDetails.isPromoApplied ? 'Y' : 'N'
  }
  private isCouponApplied() {
    return this.chargeDetails && this.chargeDetails.isCouponApplied ? 'Y' : 'N'
  }
  private isPointsApplied() {
    return this.chargeDetails && this.chargeDetails.isPointsApplied ? 'Y' : 'N'
  }
  private getPromos() {
    return this.isPromoApplied() ? this.chargeDetails.appliedpromocodedtls : null;
  }
  private getCoupons() {
    return this.isCouponApplied() ? this.chargeDetails.appliedcouponcodedtls : null;
  }
  private getPoints() {
    return this.isPointsApplied() ? this.chargeDetails.appliedPoints : null;
  }

  private generateTransactionParams() {
    let params = {
      linkedPersonId: this.patient['personid'],
      patientId: this.patient['id'],
      amount: this.user.fixDecimalPlaces(Number(this.chargeDetails.netPayable)),
      currency: this.currency_code,
      episodeType: 'R',
      encounterId: '0',
      facilityId: this.facilityId,
      requestType: 'APPTBK',
      resourceDetails: {
        billingGroup: this.billingGroup,
        insuranceFinDtls: this.patientInsurance
      }
    };
    return params;
  }

  private generateRegistrationParams() {
    var body = {
      resourceId: this.practitioner['id'],
      uhid: this.patient['id'],
      facilityId: this.facilityId,
      clinicCode: this.slot['clinicCode'],
      date: this.user.getMoment(this.slot['date']).format("YYYY-MM-DD"),
      time: this.slot['slot'],
      visitType: this.visitType,
      patientPersonId: this.patient['personid'],
      personName: this.patient['name'],
      dob: this.user.getMoment(this.patient['dob']).format("YYYY-MM-DD"),
      gender: this.patient['gender'],
      practitionerName: this.translatePipe.transform(this.practitioner['name'], 'LOCALE', 'name'),
      specialityName: this.translatePipe.transform(this.speciality['name'], 'LOCALE', 'name'),
      patientName: this.patient['name'],
      isPromoCodeAppliedYn: this.isPromoApplied(),
      isCouponAppliedYn: this.isCouponApplied(),
      isPointsAppliedYn: this.isPointsApplied(),
      coupons: this.getCoupons(),
      appliedPromoCodes: this.getPromos(),
      appliedPoints: this.getPoints(),
      billingGroup: this.billingGroup,
      insuranceFinDtls: this.patientInsurance,
      referralCode: this.referralCode ? this.referralCode : null,
      source: 'PXPORTAL',
      trace: 'CONFIRM',
      alias: this._input.in?.['alias'],
      userAction: this.userAction
    }
    if (this.freeFollowUpYn == 'Y')
      body['freeFollowUpYn'] = this.freeFollowUpYn

    if (this.patientInsurance) {
      body['isInsurance'] = true;
    }
    if (this.validationDetails)
      body['policyValidationDetails'] = this.validationDetails
    return body;
  }

  private generateReturnParams(response) {
    let transaction_id = response['transaction_id'];
    let date = this.user.getMoment(this.slot['date']).format("YYYYMMDD");
    let slot = this.user.getMoment(this.slot['slot'], 'HH:mm').format('HHmm')
    let visitType = this.visitType == 'VIDEO' ? 'V' : 'H';
    let flowType = this.flowType == 'BOOKING' ? 'B' : 'R';
    let params: any = {
      orderid: transaction_id,
      f: this.facilityId,
      s: this.speciality['id'],
      p: this.practitioner['id'],
      d: date,
      t: slot,
      h: this.patient['id'],
      v: visitType,
      c: flowType,
      pt: this.payType,
      cost: this.user.fixDecimalPlaces(Number(this.chargeDetails.netPayable)),
      ff: this.freeFollowUpYn,
      dis: 0,
      g: this.billingGroup
    }
    params = Object.keys(params).map(key => {
      if (params[key]) return `${key}=${params[key]}`;
      return ''
    }).join('&');
    return params;
  }

  private initTransaction() {
    this.generateVisitGTM();
    let payload = this.generateTransactionParams();
    let request = this.generateRegistrationParams()
    payload['resourceDetails']['sourceRequest'] = _.cloneDeep(request)
    this.apptService.startTransaction(payload, request).subscribe((response: any) => {
      let appointment = {
        _orderId: response['transaction_id'],
        saved_card_token: response['saved_card_token'],
        saved_card_details: response['savedCards'] && response['savedCards'].length ? response['savedCards'].map(c => c['cardsInfo']) : [],
        saved_stc_details: response['savedSTCNumber'] && response['savedSTCNumber'].length ? response['savedSTCNumber'].map(c => c['cardsInfo']) : [],
        uhid: this.patient['id'],
        email: this.patient['email'] || environment.DEFAULT_EMAIL,
        mobile_no: ''
      }
      let url = window['location'];

      const params = this.generateReturnParams(response);

      let diff = (href, origin) => href.split(origin).join('');
      let returnURL = diff(url['href'].split('?')[0], url['origin'] + '/') + `?s=1&method=APPTBK&p=${btoa(params)}`;
      let paymentInfo = {
        _returnURL: returnURL,
        currency: this.user.getCurrencyForPayment() || this.currency_code,
        charge: this.user.fixDecimalPlaces(Number(this.chargeDetails.netPayable)),
        paymentgatewayurl: this.paymentGatewayUrl
      }
      if (response['blockedExpiryDateTime']) {
        appointment['blockedExpiryDateTime'] = response['blockedExpiryDateTime'];
        console.log("blockedExpiryDateTime from now", response['blockedExpiryDateTime'], moment(response['blockedExpiryDateTime']).diff(moment(), 'minutes'))
      }
      if (response['blockId']) {
        appointment['blockId'] = response['blockId'];
      }
      if (request['facilityId'])
        appointment['facilityId'] = request['facilityId'];

      this.paymentService.setPaymentParams(appointment, paymentInfo).subscribe(_ => {
        this.action.emit({ name: 'openPaymentCheckout' });
      })
      this.apptService.setSlotBlockRouteInfo(false)
    }, err => {
      console.log('startTransaction', err, err.includes('HPEM130'));
      if (err.includes('HPEM130'))
        this.openSlotBlockPrompt(err);
    })
  }

  public bookAppointment(type?: string) {
    this.generateVisitGTM()
    console.log("this.patient", this.patient)
    let data = this.generateRegistrationParams()
    this.user.confirmAppointment(data).subscribe(response => {
      let d = {
        name: 'book_success',
        status: 'S',
        amount_to_be_paid: this.user.fixDecimalPlaces(Number(this.chargeDetails.netPayable)),
        currency: this.currency_code,
        category: this.billingGroup ? this.billingGroup : 'CASH',
        medium: 'FACILITY'
      }
      if (this.hasDiscount) {
        d['amount_to_be_paid'] = this.user.fixDecimalPlaces(this.payableCharge)
      }
      this.generateVisitGTMConfirmation(response)
      this.completeSelection(d);
    },err=>{
      console.log('confirmAppointment', err, err.includes('HPEM130'));
      if (err.includes('HPEM130'))
        this.openSlotBlockPrompt(err);
    })
  }

  // FROM EXISTING FLOW
  private completeSelection(data) {
    this.complete.emit({ confirm: data });
  }
  private generateVisitGTM() {
    if (this.visitType) {
      let obj = {
        'payment_option': this.payType == 'X' ? 'Insurance' : 'Credit Card',
        'value': this.user.fixDecimalPlaces(this.user.fixDecimalPlaces(Number(this.chargeDetails.netPayable)))
      };
      let funnelNames = { 'VIDEO': 'Online Consultation', 'VIDEO-CONSULT-NOW': 'Instant Consultation' }
      let eevent = 'step_option_payment';
      let funnel_name = funnelNames[this.visitType] ? funnelNames[this.visitType] : 'Clinic Appointments';
      this.gtmService.pushGTMEvent(funnel_name, eevent, obj);
    }
  }
  private generateVisitGTMConfirmation(response) {
    let d = {
      name: 'book_success',
      status: 'S',
      amount_to_be_paid: this.user.fixDecimalPlaces(Number(this.chargeDetails.netPayable)),
      currency: this.currency_code,
      category: this.billingGroup ? this.billingGroup : 'CASH',
      medium: 'FACILITY'
    }
    if (this.hasDiscount) {
      d['amount_to_be_paid'] = this.user.fixDecimalPlaces(this.payableCharge)
    }
    let eevent = 'appointment_confirmation',
      funnel_name = 'Clinic Appointments';
    if (this.visitType == 'VIDEO')
      funnel_name = 'Online Consultation';
    if (this.visitType == 'VIDEO-CONSULT-NOW')
      funnel_name = 'Instant Consultation';
    this.gtmService.pushGTMEvent(funnel_name, eevent, { value: +d['amount_to_be_paid'], booking_no: response['data']['apptRefNumber'] });
  }
  public goBack() {
    this.action.emit({ name: "Reject" })
  }
  public proceedWithCash() {
    let { chargeData, discountData } = this.promptPreset
    this.proceedApptFlow(chargeData, discountData);
    this.showCashPrompt = false;
    this.promptLabels = null;
    this.setPromptPreset(null, null)
  }
  public proceedWithIns() {
    let { chargeData, discountData } = this.promptPreset
    this.proceedApptFlow(chargeData, discountData);
    this.showInsPrompt = false;
    this.promptLabels = null;
    this.setPromptPreset(null, null)
  }
  public retrievePaymentWithCash() {
    let { chargeData, discountData } = this.promptPreset
    this.getAppointmentCharges(true, chargeData)
    this.showInsPrompt = false;
    this.promptLabels = null;
  }

  public call() {
    this.user.openLocation("tel:" + this.emerNumber);
  }
  public setReferralCode(ev) {
    console.log("-======= referralCode ====", ev)
    this.referralCode = ev.referralCode
  }
  public switchActions(type) {
    this.action.emit({ name: type })
  }
  public goBackSlotBlockPrompt() {
    this.showBlockSlotPrompt = false;
    this.fromSlotBlockPrompt = true;
  }
  public goBackToSlotView() {
    this.showBlockSlotPrompt = false;
    this.fromSlotBlockPrompt = false;
    this.showPolicyExpirePrompt = false;
    this.policyInfo = null;
    this.apptService.setSlotBlockRouteInfo(true)
    this.action.emit({ name: 'choose_slot' });
  }
  public goBackPolicyPrompt() {
    this.showPolicyExpirePrompt = false;
  }
  public gotoMainRoute() {
    this.action.emit({ name: 'CLOSEMODAL' });
  }
  public openPolicyExpirePrompt(policyInfo) {
    this.showPolicyExpirePrompt = true;
    this.policyInfo = policyInfo;
  }
  public openInsuranceModal() {
    this.showInsuranceModal = true;
  }
  public closeInsuranceModal() {
    this.showInsuranceModal = false
  }

  //Promo and coupon logics
  private calculatePayable() {
    let couponDiscount = this.chargeDetails.couponDiscount || 0;
    let pointsDiscount = this.pointsType == 'PAYMENT' ?  (this.chargeDetails.pointsDiscount || 0) : 0;
    this.chargeDetails.netPayable = this.chargeDetails.discountedPayable - couponDiscount - pointsDiscount;
    this.chargeDetails._payableWithoutCoupon = this.chargeDetails.netPayable + couponDiscount;
    this.chargeDetails._payableWithoutPoints = this.chargeDetails.netPayable + pointsDiscount;
    this.resetPaymentFlow()
  }

  private resetPaymentFlow() {
    if (Number(this.chargeDetails.netPayable) <= 0) {
      this.chargeDetails.netPayable = 0;
      this.canPay = false;
    } else {
      this.canPay = true;
    }
    this.chargeDetails.netPayable = this.user.fixDecimalPlaces(Number(this.chargeDetails.netPayable));
    this.chargeDetails = _.cloneDeep(this.chargeDetails)
    console.log('chargeDetails', this.chargeDetails)
    setTimeout(_ => {
      this.cdf.detectChanges();
      this.fetchingCharge = false
    }, 500)
  }

  public applyingPromoCode(event) {
    this.fetchingCharge = event.status
  }
  public applyingCouponCode(event) {
    this.fetchingCharge = event.status
  }
  public applyingPoints(event) {
    this.fetchingCharge = event.status
  }
  public appliedPromocode(event) {
    if (event.type == 'APPLY') {
      this.applyDiscount(event.data)
    } else if (event.type == 'REMOVE') {
      this.removeDiscounts();
    }
  }
  public couponApplied(event) {
    if (event.type == 'APPLY') {
      this.chargeDetails.couponTitle = []
      this.applyCouponcode(event.data)
    } else if (event.type == 'REMOVE') {
      this.removeCouponCode()
    }
  }
  public pointsApplied(event) {
    if (event.type == 'APPLY') {
      this.chargeDetails.pointsData = {}
      this.applyPoints(event.data)
    } else if (event.type == 'REMOVE') {
      this.removePoints()
    }
  }

  private applyDiscount(response) {
    this.chargeDetails.appliedpromocodedtls = response.appliedpromocodedtls;
    this.chargeDetails.promoDiscount = Number(response.calculatedDiscountAmt);
    this.chargeDetails.netPayable = Number(response.netservicecharge)
    this.chargeDetails.discountedPayable = Number(response.netservicecharge)
    this.chargeDetails.isPromoApplied = response.appliedpromocodedtls?.length > 0;
    if(response.netchargedtls && response.netchargedtls.length>0){
      this.chargeVatDetails['vatDisplayAmount']=this.calculateVatDisplayAmount(response.netchargedtls[0].taxAmount,response.netchargedtls[0].taxExemptAmount);
    }
    if (response.pointsApplied) {
      this.applyPoints({ pointsDerivative: response.pointsApplied })
    } else {
      this.removePoints()
    }
    this.handlePostDiscount()
  }
  public removeDiscounts() {
    this.fetchingCharge = true;
    this.chargeDetails.netPayable = Number(this.chargeDetails.totalAmount)
    this.chargeDetails.discountedPayable = Number(this.chargeDetails.totalAmount)
    this.chargeDetails.isPromoApplied = false;
    this.chargeDetails.promoDiscount = 0;
    this.removePoints()
    this.handlePostDiscount()
  }
  private handlePostDiscount() {
    let isCouponHandled = this.coupon && this.chargeDetails.isCouponApplied
    let isPointsHandled = this.points && this.chargeDetails.isPointsApplied && this.pointsType == 'PAYMENT'

    if (isCouponHandled || isPointsHandled) {
      if (isCouponHandled) this.coupon.removeAllCoupons(true);
      if (isPointsHandled) this.points.removeAllPoints(!isCouponHandled);
    } else {
      this.calculatePayable()
    }
  }
  private applyCouponcode(response) {
    this.chargeDetails.appliedcouponcodedtls = response.appliedcouponcodedtls;
    this.chargeDetails.couponTitle = this.chargeDetails.appliedcouponcodedtls.map(item => item.couponTitle)
    this.chargeDetails.couponDiscount = Number(response.value);
    this.chargeDetails.isCouponApplied = response.appliedcouponcodedtls.length > 0;
    this.calculatePayable()
  }
  private removeCouponCode() {
    this.chargeDetails.appliedcouponcodedtls = null
    this.chargeDetails.couponTitle = []
    this.chargeDetails.isCouponApplied = false;
    this.chargeDetails.couponDiscount = 0;
    this.calculatePayable()
  }
  private applyPoints(response) {
    console.log('applypoints', response)
    this.chargeDetails.appliedPoints = response.pointsDerivative;
    this.chargeDetails.pointsDiscount = Number(response.pointsDerivative.amount);
    this.chargeDetails.isPointsApplied = this.chargeDetails.pointsDiscount > 0
    if(this.pointsType == 'PAYMENT')
      this.calculatePayable()
  }
  private removePoints() {
    this.chargeDetails.appliedPoints = null;
    this.chargeDetails.pointsDiscount = 0;
    this.chargeDetails.isPointsApplied = false;
    if (this.pointsType == 'PAYMENT')
      this.calculatePayable()

    this.calculateVatCharges(this.chargeVatInfoRef);
  }

  private calculateVatCharges(parsedCharge){
    let chargeVatDetails = parsedCharge;
    chargeVatDetails['vatDisplayAmount']=this.calculateVatDisplayAmount(chargeVatDetails.vatAmount,chargeVatDetails.vatExemptedAmt)
    this.chargeVatDetails=chargeVatDetails;
    console.info('chargeVatDetails',this.chargeVatDetails)
  }

  private calculateVatDisplayAmount(vatamt,vatexpamt){
    let vatAmount, vatExemptedAmt,vatDisplayAmount;
    vatAmount = (vatamt && vatamt!='') ? parseFloat(vatamt) : 0;
    vatExemptedAmt = (vatexpamt && vatexpamt!='') ? parseFloat(vatexpamt) : 0;
    vatDisplayAmount = vatAmount - vatExemptedAmt;
    vatDisplayAmount = vatDisplayAmount.toString();
    return vatDisplayAmount;
  }

}
